var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "createApp" }, [
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "searchWrapper" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { width: "440px" },
              attrs: {
                "label-position": "right",
                "label-width": "100px",
                model: _vm.formInline,
                rules: _vm.rules,
              },
            },
            [
              _c("h1", [_vm._v("基础信息")]),
              _c(
                "el-form-item",
                { attrs: { label: "官网目录", prop: "filePath" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      placeholder: "请输入官网目录",
                      maxlength: "100",
                    },
                    model: {
                      value: _vm.formInline.filePath,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "filePath", $$v)
                      },
                      expression: "formInline.filePath",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "apk名称", prop: "fileName" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      placeholder: "请输入apk名称",
                      maxlength: "100",
                    },
                    model: {
                      value: _vm.formInline.fileName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "fileName", $$v)
                      },
                      expression: "formInline.fileName",
                    },
                  }),
                ],
                1
              ),
              _c("h1", [_vm._v("FTP服务器信息")]),
              _c(
                "el-form-item",
                { attrs: { label: "IP地址", prop: "ipAddress" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      placeholder: "请输入IP地址",
                      maxlength: "15",
                    },
                    model: {
                      value: _vm.formInline.ipAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "ipAddress", $$v)
                      },
                      expression: "formInline.ipAddress",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "端口", prop: "port" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      placeholder: "请输入端口",
                      maxlength: "5",
                    },
                    model: {
                      value: _vm.formInline.port,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "port", $$v)
                      },
                      expression: "formInline.port",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "用户名", prop: "userName" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      placeholder: "请输入用户名",
                      maxlength: "20",
                    },
                    model: {
                      value: _vm.formInline.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "userName", $$v)
                      },
                      expression: "formInline.userName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "密码", prop: "password" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "password",
                      autocomplete: "new-password",
                      placeholder: "请输入密码",
                      maxlength: "36",
                    },
                    model: {
                      value: _vm.formInline.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "password", $$v)
                      },
                      expression: "formInline.password",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "20px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.submitData },
                },
                [_vm._v("确认同步")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "info" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("dialogFtpInfoShow", false)
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }